import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import About from './About';
import Section from './Section';
import Info from './Info';
import Footer from './Footer';
import Header from './Header';
const Home = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);
  return (
    <>
    
<Header/>
      <About />
      <Section />
      <Info />
      <Footer />
    </>
  )
}

export default Home
