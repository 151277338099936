import React from "react";
import "./Installers.css";
import { FaFacebook, FaInstagram } from "react-icons/fa";
const Installers = () => {
  return (
    <>
      <div className="installers-img">
        <div className="installers-head ">
          <div className="installers-overlay">
            <h2 className="heading">OUR INSTALLERS</h2>
            <p className="installers-para  " style={{ textAlign: "justify" }}>
              As a trusted Armure dealer, we prioritize your satisfaction by
              delivering excellence in every application of our Paint Protection
              Film. Our certified installers are well-versed in the intricacies
              of PPF installation, ensuring a seamless and virtually
              undetectable layer of protection for your vehicle's paintwork.
              Armure's PPF not only shields against external elements but also
              preserves the original aesthetics of your car, maintaining its
              glossy finish for years to come.Partnering with us means gaining
              access to top-tier Armure products, backed by our commitment to
              customer satisfaction and professional service. Discover the
              ultimate in automotive protection with Armure's Paint Protection
              Film where innovation meets preservation. Visit our certified
              installer team today to experience the future of car care.
            </p>
          </div>
        </div>
      </div>
      <div className="container  installers">
        <div className="row justify-content-center ">
          <div className="col-lg-12 ">
            <div className="list-group row-gap-5 text-start mt-5">
              <div
                className="list-group-item border-0"
                style={{ color: "#febb3e", backgroundColor: "#0e1c39" }}
              >
                <div className="row fw-bold">
                  <div className="col-2 ">Name</div>
                  <div className="col-3 ">City</div>
                  <div className="col-3">Address</div>
                  <div className="col-2">Phone</div>
                  <div className="col-2">Links</div>
                </div>
              </div>

              <div className="list-group-item bg-transparent text-white border-0">
                <div className="row ">
                  <div className="col-2">Detailing Hub </div>
                  <div className="col-3  ">Faisalabad</div>
                  <div className="col-3">
                    Commercial Market, F-Block, Eden Valley, Faisalabad.
                  </div>
                  <div className="col-2">0311-2200767</div>
                  <div className="col-2 social-media-pages">
                    <a
                      className="text-warning"
                      href="https://www.facebook.com/detailinghub797?mibextid=LQQJ4d"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaFacebook className="social-icon" />
                    </a>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <a
                      className="text-warning"
                      href="https://www.instagram.com/detailinghub.fsd?igsh=YjNxaDV4Nmo5ZXdo&utm_source=qr"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaInstagram className="social-icon" />
                    </a>
                  </div>
                </div>
              </div>

              <div className="list-group-item bg-transparent text-white border-0">
                <div className="row text-start">
                  <div className="col-2">Usmanz Autoplex</div>
                  <div className="col-3 ">Islamabad</div>
                  <div className="col-3">
                    Street-12, Golra NPF, E-11/4 Islamabad.
                  </div>
                  <div className="col-2">0333-6831109</div>
                  <div className="col-2 social-media-pages">
                    <a
                      className="text-warning"
                      href="https://www.facebook.com/UZautoplex"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaFacebook className="social-icon" />
                    </a>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <a
                      className="text-warning"
                      href="https://www.instagram.com/usmanzautoplex/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaInstagram className="social-icon" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="list-group-item bg-transparent text-white border-0">
                <div className="row ">
                  <div className="col-2">Shift Car Studio</div>
                  <div className="col-3 ">Islamabad</div>
                  <div className="col-3">
                    SB Marketing Road, G-9 Markaz, G-9 Islamabad.
                  </div>
                  <div className="col-2">0311-1069873</div>
                  <div className="col-2 social-media-pages">
                    <a
                      className="text-warning"
                      href="http://Facebook.com/shiftcarstudio"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaFacebook className="social-icon" />
                    </a>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <a
                      className="text-warning"
                      href="http://Instagram.com/shiftcarstudio"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaInstagram className="social-icon" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="list-group-item bg-transparent text-white border-0">
                <div className="row ">
                  <div className="col-2">Metropolis Car Care (MCC)</div>
                  <div className="col-3 ">Islamabad</div>
                  <div className="col-3">
                   Industrial Area, Potohar Road, I-9, Islamabad.
                  </div>
                  <div className="col-2">0335-5828168</div>
                  <div className="col-2 social-media-pages">
                    <a
                      className="text-warning"
                      href="#"
                      target=""
                      rel="noopener noreferrer"
                    >
                      <FaFacebook className="social-icon" />
                    </a>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <a
                      className="text-warning"
                      href=""
                      target=""
                      rel="noopener noreferrer"
                    >
                      <FaInstagram className="social-icon" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="list-group-item bg-transparent text-white border-0">
                <div className="row ">
                  <div className="col-2">Restor FX Karachi</div>
                  <div className="col-3 ">Karachi</div>
                  <div className="col-3">
                    Mall Shoping Centre, 7th Commercial lane, Zamzama DHA-5
                    Karachi.
                  </div>
                  <div className="col-2">0310-1111889</div>
                  <div className="col-2 social-media-pages">
                    <a
                      className="text-warning"
                      href="https://www.facebook.com/RFXCDHAKHI"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaFacebook className="social-icon" />
                    </a>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <a
                      className="text-warning"
                      href="https://www.instagram.com/restorfx.karachi_dha/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaInstagram className="social-icon" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="list-group-item bg-transparent text-white border-0">
                <div className="row ">
                  <div className="col-2">Maaxshine Auto Care</div>
                  <div className="col-3 ">Lahore</div>
                  <div className="col-3">
                    E-38, Tipu Sultan Lane, Street-6 Cavalary Ground, Lahore
                    Cantt.
                  </div>
                  <div className="col-2">0322-4355842</div>
                  <div className="col-2 social-media-pages">
                    <a
                      className="text-warning"
                      href="https://www.facebook.com/Maxshineautocare?mibextid=ZbWKwL"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaFacebook className="social-icon" />
                    </a>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <a
                      className="text-warning"
                      href="https://www.instagram.com/maxshineautocare/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaInstagram className="social-icon" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="list-group-item bg-transparent text-white border-0">
                <div className="row ">
                  <div className="col-2">Platinum Motor Works</div>
                  <div className="col-3 ">Lahore</div>
                  <div className="col-3">
                    Service Lane, Ring Road, Behind Karak Khel, State Life
                    Interchange, DHA Lahore.
                  </div>
                  <div className="col-2">0321-4973232</div>
                  <div className="col-2 social-media-pages">
                    <a
                      className="text-warning"
                      href="https://www.facebook.com/Platinum.CarStudio"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaFacebook className="social-icon" />
                    </a>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <a
                      className="text-warning"
                      href="https://www.instagram.com/platinum.carstudio/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaInstagram className="social-icon" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="list-group-item bg-transparent text-white border-0">
                <div className="row ">
                  <div className="col-2">Toyota Walton Motors</div>
                  <div className="col-3 ">Lahore</div>
                  <div className="col-3">
                    Main Walton Road, Lahore.
                  </div>
                  <div className="col-2">0333-4505365</div>
                  <div className="col-2 social-media-pages">
                    <a
                      className="text-warning"
                      href="https://www.facebook.com/ToyotaWaltonMotors?mibextid=LQQJ4d"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaFacebook className="social-icon" />
                    </a>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <a
                      className="text-warning"
                      href="https://www.instagram.com/toyotawalton/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaInstagram className="social-icon" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="list-group-item bg-transparent text-white border-0">
                <div className="row ">
                  <div className="col-2">Auto Aeon Detailing & Car Wash</div>
                  <div className="col-3 ">Multan</div>
                  <div className="col-3">
                    Shop-22 Model Town, B-Commercial Market Multan.
                  </div>
                  <div className="col-2">0331-7744202</div>
                  <div className="col-2 social-media-pages">
                    <a
                      className="text-warning"
                      href="https://www.facebook.com/autoaeon"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaFacebook className="social-icon" />
                    </a>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <a
                      className="text-warning"
                      href="https://www.instagram.com/explore/locations/1610148312598415/auto-aeon-detailing-car-care/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaInstagram className="social-icon" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="list-group-item bg-transparent text-white border-0">
                <div className="row ">
                  <div className="col-2">Gulistan Car Studio</div>
                  <div className="col-3 ">Multan</div>
                  <div className="col-3">
                    House-3, Gulistan Colony, Old Bahawalpur Road Multan.
                  </div>
                  <div className="col-2">0300-6344556</div>
                  <div className="col-2 social-media-pages">
                    <a
                      className="text-warning"
                      href="https://www.facebook.com/gulistancarstudio"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaFacebook className="social-icon" />
                    </a>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <a
                      className="text-warning"
                      href="https://www.instagram.com/multancarstudio/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaInstagram className="social-icon" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="list-group-item bg-transparent text-white border-0">
                <div className="row ">
                  <div className="col-2">Ali Auto Detailing</div>
                  <div className="col-3 ">Peshawar</div>
                  <div className="col-3">Ali CNG Byco Filling Station, Bara Gate, Bara Road, Peshawar.</div>
                  <div className="col-2">0333-9608536</div>
                  <div className="col-2 social-media-pages">
                    <a
                      className="text-warning"
                      href="https://www.facebook.com/Alicngbyco"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaFacebook className="social-icon" />
                    </a>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <a
                      className="text-warning"
                      href="https://www.instagram.com/explore/locations/1629937780640127/ali-auto-detailing/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaInstagram className="social-icon" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="list-group-item bg-transparent text-white border-0">
                <div className="row ">
                  <div className="col-2">Gloss Treatment Auto</div>
                  <div className="col-3 ">Rawalpindi</div>
                  <div className="col-3">
          22/A, C-Block, Satellite Town, Rawalpindi.
                  </div>
                  <div className="col-2">0333-5112962</div>
                  <div className="col-2 social-media-pages">
                    <a
                      className="text-warning"
                      href="https://www.facebook.com/GlossTreatment?mibextid=ZbWKwL"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaFacebook className="social-icon" />
                    </a>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <a
                      className="text-warning"
                      href="https://www.instagram.com/explore/locations/1878844975741906/gloss-treatment-auto/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaInstagram className="social-icon" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="list-group-item bg-transparent text-white border-0">
                <div className="row ">
                  <div className="col-2">Restor FX Center Pakistan</div>
                  <div className="col-3 ">Rawalpindi</div>
                  <div className="col-3">
                    Main Airport Road, Near Bank Alfalah Fazal Town, Service
                    Road Rawalpindi.
                  </div>
                  <div className="col-2">0345-8500438</div>
                  <div className="col-2 social-media-pages">
                    <a
                      className="text-warning"
                      href="https://www.facebook.com/RFXCPK?mibextid=LQQJ4d"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaFacebook className="social-icon" />
                    </a>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <a
                      className="text-warning"
                      href="https://instagram.com/restorfxcenterpk?igshid=MzMyNGUyNmU2YQ%3D%3D&utm_source=qr"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaInstagram className="social-icon" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="list-group-item bg-transparent text-white border-0">
                <div className="row ">
                  <div className="col-2">Mobil AutoSpa </div>
                  <div className="col-3 ">Rawalpindi</div>
                  <div className="col-3">
                    Peshawar Road, Naseerabad, Rawalpindi.
                  </div>
                  <div className="col-2">0332-3060166</div>
                  <div className="col-2 social-media-pages">
                    <a
                      className="text-warning"
                      href="https://www.facebook.com/autospapk"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaFacebook className="social-icon" />
                    </a>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <a
                      className="text-warning"
                      href="https://www.instagram.com/explore/locations/193754334423765/mobil-autospa/nearby/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaInstagram className="social-icon" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="list-group-item bg-transparent text-white border-0">
                <div className="row ">
                  <div className="col-2">Auto Tech</div>
                  <div className="col-3 ">Sargodha</div>
                  <div className="col-3">Railway Road Sargodha.</div>
                  <div className="col-2">0300-8600960</div>
                  <div className="col-2 social-media-pages">
                    <a
                      className="text-warning"
                      href="https://www.facebook.com/profile.php?id=100063797560559"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaFacebook className="social-icon" />
                    </a>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <a
                      className="text-warning"
                      href="https://www.instagram.com/explore/locations/280413295926081/relway-raod-sargodha/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaInstagram className="social-icon" />
                    </a>
                  </div>
                </div>
              </div>

              <div
                className="list-group-item border-0"
                style={{ color: "#febb3e", backgroundColor: "#121f3f" }}
              >
                <div className="row ">
                  <div className="col-3"></div>
                  <div className="col-4"></div>
                  <div className="col-2"></div>
                  <div className="col-3"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Installers;
