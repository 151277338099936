import React from 'react'
import './Platinum.css'
import Platinumicon from "./Platinumicon.png";
import Platinumtable from "./Platinumtable.png";
const Platinum = () => {
  return (
    <>
    <div className='platinumpage'>
       {/* Section with a background image and two headings */}
       <section className="bg-image-section d-flex align-items-start justify-content-start ">
          <div className="text-container  ">
            <h3 className="top ">LIFETIME</h3><br />
            <h3 className="bottom">WARRANTY</h3>
          </div>
        </section>

        {/* Section with text and image */}
        <section className="d-flex flex-column flex-lg-row  section-content">
          <div className="left-content col-lg-6 col-12">
            <h3 className="up">ARMURE</h3>
            <br />
            <h2 className="centre">PLATINUM</h2>
            <br />
            
            
            <ul className="mt-4 points">
              <li>
               Clearest PPF: Premium quality film for crystal-clear protection.
              </li>
              <li>
              Unparalleled protection: Armure Platinum PPF is the pinnacle of -paint protection films.
              </li>
              <li>
               Lifetime warranty: Backed by a lifetime warranty for peace of mind.
              </li>
              <li>Unmatched durability: Provides exceptional defense against swirls, scratches, and yellowing.</li>
              <li> Glossy finish preservation: Ensures your car maintains its glossy appearance for a lifetime of driving pleasure.</li>
              
            </ul>
          </div>
          <div className="right-content col-lg-6 col-12">
            <img src={Platinumicon} alt="Armure Ultra" className="img-fluid" />
          </div>
        </section>

        {/* Full-width image */}
        <section className="90 table">
          <img src={Platinumtable} alt="Full Width" className="img-fluid w-90" />
        </section>
    </div>
    </>
  )
}

export default Platinum
