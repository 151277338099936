import React from 'react';
import './Glossblack.css';
import Glossblackicon from "./Glossblackicon.png";
import Glossblacktable from "./Glossblacktable.png";

const Glossblack = () => {
  return (
    <>
    <div className='glossblackpage'>
      {/* Section with a background image and two headings */}
      <section className="bg-image-section d-flex align-items-start justify-content-start ">
        <div className="text-container ">
          <h3 className="top">7 YEARS</h3><br />
          <h3 className="bottom">WARRANTY</h3>
        </div>
      </section>

      {/* Section with text and image */}
      <section className="section-content d-flex flex-column flex-lg-row">
        <div className="left-content ">
          <h3 className="up">ARMURE</h3>
          <h2 className="midle">GLOSS BLACK</h2>
          
         
          <ul className=" points">
            <li>
              Restoration brightening coating: Our paint <br /> protection film features  a restoration brightening coating,<br /> enhancing the appearance of your  vehicle's paint<br /> and providing a glossy finish.
            </li>
            <li>
              Nano ceramic material super: Crafted from nano ceramic <br /> material,  our film offers superior protection against scratches, UV rays, and environmental damage, ensuring long-lasting performance.
            </li>
            <li>
              Dispersion top coat: The dispersion top coat on our film provides added durability and resistance to stains, ensuring your vehicle's paint stays looking its best.
            </li>
            <li>
              High Performance Adhesive Layer: Equipped with a high-performance adhesive layer, our film ensures strong and lasting adhesion to your vehicle's surface, providing reliable protection.
            </li>
            <li>
              TPU-based material: Made from TPU-based material, our film offers flexibility and resilience, conforming seamlessly to your vehicle's curves while providing maximum protection.
            </li>
          </ul>
        </div>
        <div className="right-content ">
          <img src={Glossblackicon} alt="Armure Ultra" className="img-fluid" />
        </div>
      </section>

      {/* Full-width image */}
      <section className="table">
        <img src={Glossblacktable} alt="Full Width" className="img-fluid w-90" />
      </section>
    </div>
    </>
  );
}

export default Glossblack;
