import React from "react";
// import React, { useEffect } from "react";
import "./Info.css";
import { useInView } from 'react-intersection-observer';
import { Link } from "react-router-dom";
import Ultra from "./Ultra.png";
import Ultimate from "./Ultimate.png";
import Platinum from "./Platinum.png";
import Glossblack from "./Glossblack.png";
import Matte from "./Matte.png";
import Windowfilm from "./Windowfilm.png";

const Info = () => {
  const [ultraRef, ultraInView] = useInView({ threshold: 0.4, triggerOnce: true });
  const [ultimateRef, ultimateInView] = useInView({ threshold: 0.4, triggerOnce: true });
  const [platinumRef, platinumInView] = useInView({ threshold: 0.4, triggerOnce: true });
  const [glossblackRef, glossblackInView] = useInView({ threshold: 0.4, triggerOnce: true });
  const [matteRef, matteInView] = useInView({ threshold: 0.4, triggerOnce: true });
  const [windowfilmRef, windowfilmInView] = useInView({ threshold: 0.4, triggerOnce: true });
  const [iM1, iM1InView] = useInView({ threshold: 0.2, triggerOnce: true });
  const [iM2, iM2InView] = useInView({ threshold: 0.2, triggerOnce: true });
  const [iM3, iM3InView] = useInView({ threshold: 0.2, triggerOnce: true });
  const [iM4, iM4InView] = useInView({ threshold: 0.2, triggerOnce: true });
  const [iM5, iM5InView] = useInView({ threshold: 0.2, triggerOnce: true });
  const [iM6, iM6InView] = useInView({ threshold: 0.2, triggerOnce: true });
  return (
    <>
     {/* 1 */}
     
     <div className="container-fluid p-0 ultra-r">
        <div className="row no-gutters d-flex align-items-center">
          <div className="col-md-6 p-0 order-2 order-md-1">
            <img src={Ultra} ref={iM1} alt="ultra" className={`img-fluid w-100 info-img ${iM1InView ? "info-img-effect" : ""}`} />
          </div>
          <div className="col-md-6 d-flex flex-column justify-content-center details order-1 order-md-2">
            <div>
              <h3>ARMURE</h3>
              <h2
                ref={ultraRef}
                className={`ultraheading ${ultraInView ? "animate__animated animate__slideInRight" : ""}`}
              >
                ULTRA
              </h2>
              <p>
                It offers reliable protection with a 5-year warranty, guarding
                your car's paint from swirls, scratches, and yellowing. With a
                glossy finish, it ensures your vehicle maintains its sleek
                appearance for years to come.
              </p>
              <div className="line-container">
                <div className="liner"></div>
                <span className="text">
                  <Link to="/ultra">
                    view <strong>more</strong>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="11"
                      height="11"
                      fill="currentColor"
                      className="bi bi-arrow-right-circle-fill mx-1"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                    </svg>
                  </Link>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 2 */}

      <div className="container-fluid p-0 ultimate-l">
        <div className="row no-gutters d-flex align-items-center">
          <div className="col-md-6  d-flex flex-column justify-content-center details ">
            <div>
              <h3>ARMURE</h3>
              <h2 ref={ultimateRef}
                className={`ultimateheading ${ultimateInView ? "animate__animated animate__slideInLeft" : ""}`}>ULTIMATE</h2>
              <p>
              It provides enhanced durability and a 7-year warranty. This advanced film offers superior defense against
swirls, scratches, and yellowing, while maintaining a
glossy finish that keeps your vehicle looking pristine.
              </p>
              <div className="line-container">
                <div className="liner"></div>
                <span className="text">
                  <Link to="/ultimate">
                    view <strong>more</strong>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="11"
                      height="11"
                      fill="currentColor"
                      className="bi bi-arrow-right-circle-fill mx-1"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                    </svg>
                  </Link>
                </span>
              </div>
            </div>
          </div>
          <div className="col-md-6 p-0 ">
            <img
              src={Ultimate}
              alt="ultimate"
              ref={iM2}
              className={`img-fluid w-100 info-img ${iM2InView ? "info-img-effect" : ""}`} />
          </div>
        </div>
      </div>

      {/* 3 */}

      <div className="container-fluid p-0 platinum-r">
        <div className="row no-gutters d-flex align-items-center">
          <div className="col-md-6 p-0 order-2 order-md-1">
            <img
              src={Platinum}
              alt="platinum"
              ref={iM3}
              className={`img-fluid w-100 info-img ${iM3InView ? "info-img-effect" : ""}`} />
          </div>
          <div className="col-md-6  d-flex flex-column justify-content-center details order-1 order-md-2">
            <div>
              <h3>ARMURE</h3>
              <h2 ref={platinumRef}
                className={`platinumheading ${platinumInView ? "animate__animated animate__slideInRight" : ""}`}>PLATINUM</h2>
              <p>
                The pinnacle of paint protection film. Backed by a lifetime
                warranty, this-top-of-the-line product offers unmatched
                durability against swirls, scratches and yellowing, ensuring
                your car maintains its glossy finish for a lifetime of driving
                pleasure.
              </p>
              <div className="line-container">
                <div className="liner"></div>
                <span className="text">
                  <Link to="/platinum">
                    view <strong>more</strong>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="11"
                      height="11"
                      fill="currentColor"
                      className="bi bi-arrow-right-circle-fill mx-1"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                    </svg>
                  </Link>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 4 */}

      <div className="container-fluid p-0 glossblack-l">
        <div className="row no-gutters d-flex align-items-center">
          <div className="col-md-6  d-flex flex-column justify-content-center details ">
            <div>
              <h3>ARMURE</h3>
              <h2  ref={glossblackRef}
                className={`glossblackheading ${glossblackInView ? "animate__animated animate__slideInLeft" : ""}`}>GLOSS BLACK</h2>
              <p>
                Experience the transformative effect of Armure Gloss Black PPF,
                allowing you to shift your car's paint from uniform to a
                captivating two-tone look. Its sleek glossy finish complements
                all paint colors, delivering the superior performance synonymous
                with cutting-edge PPF technology.
              </p>
              <div className="line-container">
                <div className="liner"></div>
                <span className="text">
                  <Link to="/glossblack">
                    view <strong>more</strong>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="11"
                      height="11"
                      fill="currentColor"
                      className="bi bi-arrow-right-circle-fill mx-1"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                    </svg>
                  </Link>
                </span>
              </div>
            </div>
          </div>
          <div className="col-md-6 p-0 ">
            <img
              src={Glossblack}
              alt="glossblack"
              ref={iM4}
              className={`img-fluid w-100 info-img ${iM4InView ? "info-img-effect" : ""}`} />
          </div>
        </div>
      </div>

      {/* 5 */}

      <div className="container-fluid p-0 matte-r">
        <div className="row no-gutters d-flex align-items-center">
          <div className="col-md-6 p-0 order-2 order-md-1">
            <img src={Matte} alt="matte" ref={iM5}
              className={`img-fluid w-100 info-img ${iM5InView ? "info-img-effect" : ""}`} />
          </div>
          <div className="col-md-6  d-flex flex-column justify-content-center details order-1 order-md-2">
            <div>
              <h3>ARMURE</h3>
              <h2 ref={matteRef}
                className={`matteheading ${matteInView ? "animate__animated animate__slideInRight" : ""}`}>MATTE</h2>
              <p>
                Armure Matte PPF delivers unparalleled durability and it serves
                as a protective shield for your vehicle's paint, sheilding it
                from scratches, stone chips and various forms of damage. In contrast to
                conventional Glossy PPF, Matte PPF offers a subtle and
                understated matte finish, providing both protection and aesthetic
                appeal.
              </p>
              <div className="line-container">
                <div className="liner"></div>
                <span className="text">
                  <Link to="/matte">
                    view <strong>more</strong>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="11"
                      height="11"
                      fill="currentColor"
                      className="bi bi-arrow-right-circle-fill mx-1"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                    </svg>
                  </Link>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 6 */}

      <div className="container-fluid p-0 windowfilm-l">
        <div className="row no-gutters d-flex align-items-center">
          <div className="col-md-6  d-flex flex-column justify-content-center details ">
            <div>
              <h3>ARMURE</h3>
              <h2 ref={windowfilmRef}
                className={`windowfilmheading ${windowfilmInView ? "animate__animated animate__slideInLeft" : ""}`}>WINDOW FILM</h2>
              <p>
                Experience unparalleled protection with our Armure window film,
                offering 100% UV and 400nm full-waveband blocking for maximum
                skin and eye safety. Enjoy vibrant colors that remain stable
                over time, free from fading or foaming. Protect yourself from
                common UVA, UVB & UVC dangers effortlessly.
              </p>
              <div className="line-container">
                <div className="liner"></div>
                <span className="text">
                  <Link to="/windowfilm">
                    view <strong>more</strong>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="11"
                      height="11"
                      fill="currentColor"
                      className="bi bi-arrow-right-circle-fill mx-1"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                    </svg>
                  </Link>
                </span>
              </div>
            </div>
          </div>
          <div className="col-md-6 p-0 ">
            <img
              src={Windowfilm}
              alt="windowfilm"
              ref={iM6}
              className={`img-fluid w-100 info-img ${iM6InView ? "info-img-effect" : ""}`} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Info;
