import React from 'react'
import './Matte.css'
import Matteicon from "./Matteicon.png";
import Mattetable from "./Mattetable.png";
const Matte = () => {
  return (
    <>
    <div className='mattepage'>
       {/* Section with a background image and two headings */}
       <section className="bg-image-section d-flex align-items-start justify-content-start ">
        <div className="text-container ">
          <h3 className="top">7 YEARS</h3><br />
          <h3 className="bottom">WARRANTY</h3>
        </div>
      </section>

      {/* Section with text and image */}
      <section className="section-content d-flex flex-column flex-lg-row">
        <div className="left-content">
          <h3 className="up">ARMURE</h3>
          <h2 className="midle">MATTE</h2>
          
         
          <ul className="mt-4 points">
            <li>
            TPU-based material: Our paint protection film is <br /> crafted from TPU-based material, offering superior<br /> durability and flexibility.
            </li>
            <li>
            Self-healing with heat: With the application of heat,<br /> our film has self-healing properties, effectively repairing<br /> minor scratches and imperfections.
            </li>
            <li>
            Non-yellowing: Rest assured that our film maintains its clarity and color over time, resisting the yellowing that can occur with inferior products.
            </li>
            <li>
            Highly resistant to swirls and scratches: Designed to withstand the rigors of daily driving, our film provides exceptional resistance against swirls and scratches, keeping your vehicle looking pristine.
            </li>
            <li>
            Max stretchability: Our film boasts maximum stretchability during installation, ensuring seamless coverage and protection for every curve and contour of your vehicle.
            </li>
          </ul>
        </div>
        <div className="right-content">
          <img src={Matteicon} alt="Armure Ultra" className="img-fluid" />
        </div>
      </section>

      {/* Full-width image */}
      <section className="table">
        <img src={Mattetable} alt="Full Width" className="img-fluid w-90" />
      </section>
    </div>
    </>
  )
}

export default Matte
