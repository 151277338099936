import React from "react";
import "./Ultra.css";
import Ultraicon from "./Ultraicon.png";
import Ultratable from "./Ultratable.png";

const Ultra = () => {
  return (
    <>
    <div className="ultrapage" >
      
        {/* Section with a background image and two headings */}
        <section className="bg-image-section d-flex align-items-start justify-content-start ">
          <div className="text-container ">
            <h3 className="top ">5 YEARS</h3><br />
            <h3 className="bottom ">WARRANTY</h3>
          </div>
        </section>

        {/* Section with text and image */}
        <section className="d-flex flex-column flex-lg-row  section-content">
          <div className="left-content col-lg-6 col-12">
            <h3 className="up">ARMURE</h3>
            <br />
            <h2 className="centre ">ULTRA</h2>
            <br />
           
            <br />
            <ul className=" points">
              <li>
                
                Protects Car Surface from stonechips, scratches & road salt.
              </li>
              <li>
                Swirl Marks and minor scratches disappear with exposure to heat
                from engine or the sun.
              </li>
              <li>
               Glossy finish
              </li>
              <li>Anti Yellowing.</li>
            </ul>
          </div>
          <div className="right-content col-lg-6 col-12">
            <img src={Ultraicon} alt="Armure Ultra" className="img-fluid" />
          </div>
        </section>

        {/* Full-width image */}
        <section className="90 table">
          <img src={Ultratable} alt="Full Width" className="img-fluid w-90" />
        </section>
      
    </div>
    </>
  );
};

export default Ultra;
