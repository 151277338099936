import React from 'react'
import './Warranty.css'
// import certificate from './certificate.jpg'
const Warranty = () => {

  return (
    <>
      {/* <img className='certificate' src={certificate} alt="certificate" /> */}
    <div className='text-warning text-center'>
      For Certified Installers Only.
    </div>
    </>
  )
}

export default Warranty
