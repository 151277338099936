import React from 'react'
import "./About.css";

const About = () => {
  return (
    <>
        <div className="outer-section" id='about'>
      <div className="inner-section">
        <h1><strong>ABOUT US</strong></h1>
        <p>At <strong>Armure PPF</strong> ,&nbsp;we pride ourselves on being the industry leader in automotive paint protection. Our innovative products are designed with drivers in mind, offering superior protection against the elements while maintaining the beauty of your car's paint. With our cutting-edge technology & commitment to quality, Armure PPF ensures that our vehicle stays looking its best, mile after mile. Trust Armure PPf to safeguard your investment and keep your car looking showroom-ready for years to come.</p>
        
      </div>
    </div>
    
    </>
  )
}

export default About
