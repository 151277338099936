import React from 'react'
import './Windowfilm.css'
import Windowfilmicon from "./Windowfilmicon.png";
import Windowfilmtable from "./Windowfilmtable.png";
import Windowfilmbottom from "./Windowfilmbottom.png";
const Windowfilm = () => {
  return (
    <>
    <div className='windowfilmpage'>
       {/* Section with a background image and two headings */}
       <section className="bg-image-section d-flex align-items-start justify-content-start  ">
        <div className="text-container ">
          <h3 className="top">10 YEARS</h3><br />
          <h3 className="bottom">WARRANTY</h3>
        </div>
      </section>

      {/* Section with text and image */}
      <section className="section-content d-flex flex-column flex-lg-row ">
        <div className="left-content">
          <h3 className="up">ARMURE</h3>
          <h2 className="midle">WINDOW FILM</h2>
          
         
          <ul className="mt-4 points">
            <li>
            Advanced ceramic technology rejects up to 97% of infrared rays.
            </li>
            <li>
            Blocks 99% of harmful UV rays, preventing over exposure.
            </li>
            <li>
            Improves energy efficiency by blocking solar heat.
            </li>
            <li>
            Ceramic, metal-free construction won't interfere with mobile phones, GPS navigation, satellite radio, and other electronic devices.
            </li>
            <li>
            Reduces interior fading Neutral, non-reflective appearance.
            </li>
            <li>
            Reduces glare.
            </li>
            <li>
            Holds broken glass together in the event of an accident.
            </li>
            <li>
            Guaranteed against fading or color change.
            </li>
          </ul>
        </div>
        <div className="right-content">
          <img src={Windowfilmicon} alt="Armure Ultra" className="img-fluid" />
        </div>
      </section>

      {/* Full-width image */}
      <section className="table">
        <img src={Windowfilmtable} alt="Full Width" className="img-fluid w-90" />
      </section>
      <section className="end">
        <img src={Windowfilmbottom} alt="Full Width" className="img-fluid w-90" />
      </section>
    </div>
    </>
  )
}

export default Windowfilm
