import React from "react";
import "./App.css";
import Home from "./Components/Home";
import Navbar from "./Components/Navbar";
import Ultra from "./Components/Ultra";
import Ultimate from "./Components/Ultimate";
import Platinum from "./Components/Platinum";
import Glossblack from "./Components/Glossblack";
import Matte from "./Components/Matte";
import Windowfilm from "./Components/Windowfilm";
import Installers from "./Components/Installers";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Warranty from "./Components/Warranty";

function App() {
  return (
    <>
      <Router>
        <div>
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/ultra" element={<Ultra />} />
            <Route path="/ultimate" element={<Ultimate />} />
            <Route path="/platinum" element={<Platinum />} />
            <Route path="/glossblack" element={<Glossblack />} />
            <Route path="/matte" element={<Matte />} />
            <Route path="/windowfilm" element={<Windowfilm />} />
            <Route path="/installers" element={<Installers />} />
            <Route path="/warranty" element={<Warranty />} />
          </Routes>
        </div>
      </Router>
    </>
  );
}
export default App;
