import React, { useEffect, useState } from "react";
import "./Header.css";
const Header = () => {
  const [bgLoaded, setBgLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    const bgImageUrl = getComputedStyle(
      document.querySelector(".front")
    ).backgroundImage.slice(5, -2);

    img.src = bgImageUrl;
    img.onload = () => setBgLoaded(true);
  }, []);

  return (
    <>
    <div className="front">
      <div className="overlay">
        <div className="container h-100">
          <div className="row h-100 align-items-center">
            <div className="col-lg-12 text-center">
              {bgLoaded ? (
                <h1 className="animate__animated animate__fadeInDown">
                  THE ULTIMATE PROTECTION
                </h1>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default Header;
