import React, { useState } from 'react';
import "./Navbar.css";
import logoarmure from "./Armure Logo.png";
import { Link, useLocation } from 'react-router-dom';

const Navbar = () => {
  const [expanded, setExpanded] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  const handleLinkClick = () => {
    setExpanded(false);
    setDropdownOpen(false);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const location = useLocation();
  const path = location.pathname;

  const linkStyle = {
    home: {
      color: path === '/' ? '#ffc107' : '',
    },
    ultra: {
      color: path === '/ultra' ? '#ffc107' : '',
      backgroundColor: path === '/ultra' ? 'white' : '',
    },
    ultimate: {
      color: path === '/ultimate' ? '#ffc107' : '',
      backgroundColor: path === '/ultimate' ? 'white' : '',
    },
    platinum: {
      color: path === '/platinum' ? '#ffc107' : '',
      backgroundColor: path === '/platinum' ? 'white' : '',
    },
    glossblack: {
      color: path === '/glossblack' ? '#ffc107' : '',
      backgroundColor: path === '/glossblack' ? 'white' : '',
    },
    matte: {
      color: path === '/matte' ? '#ffc107' : '',
      backgroundColor: path === '/matte' ? 'white' : '',
    },
    windowfilm: {
      color: path === '/windowfilm' ? '#ffc107' : '',
      backgroundColor: path === '/windowfilm' ? 'white' : '',
    },
    installers: {
      color: path === '/installers' ? '#ffc107' : '',
    },
    warranty: {
      color: path === '/warranty' ? '#ffc107' : '',
    }
  };

  const isProductPage = ["/ultra", "/ultimate", "/platinum", "/glossblack", "/matte", "/windowfilm"].includes(path);

  return (
    <>
    <nav className={`navbar navbar-expand-lg ${expanded ? 'show' : ''}`}>
      <div className="container-fluid">
        <button
          className="navbar-toggler"
          type="button"
          aria-controls="navbarNavDropdown"
          aria-expanded={expanded}
          aria-label="Toggle navigation"
          onClick={handleToggle}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <Link to='/' className="navbar-brand">
          <img src={logoarmure} className="navbarlogo img-fluid d-inline-block align-text-top" alt="nav-logo" />
        </Link>
        <div className={`collapse navbar-collapse ${expanded ? 'show' : ''}`} id="navbarNavDropdown">
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link to="/" className="nav-link" aria-current="page" style={linkStyle.home} onClick={handleLinkClick}>
                HOME
              </Link>
            </li>
            <li className={`nav-item dropdown ${dropdownOpen ? 'show' : ''}`}>
              <Link
                className="nav-link dropdown-toggle tb"
                to="#"
                style={isProductPage ? { color: '#ffc107' } : {}}
                role="button"
                aria-expanded={dropdownOpen}
                onClick={(e) => { e.preventDefault(); toggleDropdown(); }}
              >
                PRODUCTS
              </Link>
              <ul className={`dropdown-menu bg-dark ${dropdownOpen ? 'show' : ''}`}>
                <li>
                  <Link className="dropdown-item text-warning" to="/ultra" style={linkStyle.ultra} onClick={handleLinkClick}>
                    ULTRA
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item text-warning" to="/ultimate" style={linkStyle.ultimate} onClick={handleLinkClick}>
                    ULTIMATE
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item text-warning" to="/platinum" style={linkStyle.platinum} onClick={handleLinkClick}>
                    PLATINUM
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item text-warning" to="/glossblack" style={linkStyle.glossblack} onClick={handleLinkClick}>
                    GLOSS BLACK
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item text-warning" to="/matte" style={linkStyle.matte} onClick={handleLinkClick}>
                    MATTE
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item text-warning" to="/windowfilm" style={linkStyle.windowfilm} onClick={handleLinkClick}>
                    WINDOW FILM
                  </Link>
                </li>
              </ul>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/warranty" style={linkStyle.warranty} onClick={handleLinkClick}>
                WARRANTY
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/installers" style={linkStyle.installers} onClick={handleLinkClick}>
                INSTALLERS
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link contact bg-dark text-warning"
                to="/#contact-us" onClick={handleLinkClick}
              >
                CONTACT US
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    </>
  );
};

export default Navbar;
