import React, { useState } from "react";
import emailjs from "emailjs-com";
import { FaWhatsapp, FaFacebook, FaInstagram , FaEnvelope,FaMapMarkerAlt} from "react-icons/fa";
import "./Footer.css";
import footerimg from './Footerimg.png'
const Footer = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const templateParams = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
    };

    console.log("Template Params:", templateParams); // Debugging

    emailjs
      .send(
        "service_1zxoncq",
        "template_4b6qcil",
        templateParams,
        "rNl3F8RbOpzXCWRxj"
      )
      .then(
        (result) => {
          console.log(result.text);
          alert("Subscription successful!");
        },
        (error) => {
          console.log(error.text);
          alert("Subscription failed. Please try again.");
        }
      );

    setFormData({
      firstName: "",
      lastName: "",
      email: "",
    });
  };

  return (
    <>
    <div className="wholefooter">
      <div className="footerimg d-flex order-2 order-md-1" >
        <img src={footerimg} alt="" />
      </div>
      <div className="fcontainer order-1 order-md-2" id="contact-us">
        <div className="fline left"></div>
        <h2 className="fheading">CONTACT US</h2>
        <div className="fline right"></div>
        {/* <div className="number">
          <a href="https://wa.me/+923002411366"
              target="_blank"
              rel="noopener noreferrer"><h2><span className="fs-4 text-warning"><FaWhatsapp/></span>&nbsp;+92 300 2411 366</h2></a>
        </div> */}
        <div className="number">
          <a href="mailto:mbabar950@gmail.com"
              target="_blank"
              rel="noopener noreferrer"><h2><span className="fs-5 text-warning"><FaEnvelope /></span>&nbsp;INFO@ARMUREPPF.COM</h2></a>
        </div>
        
        {/* <div className="number">
          <h2><span className="fs-4 text-warning"><FaMapMarkerAlt />&nbsp;</span>ISLAMABAD, PAKISTAN</h2>
        </div> */}
        <div className="brand">
          <h3>
            <span style={{ color: "#ffc13b" }}>ARMURE</span> | THE ULTIMATE
            PROTECTION
          </h3>
        </div>

        <form className="form-container" onSubmit={handleSubmit}>
          <input
            type="text"
            name="firstName"
            placeholder="NAME"
            className="input-name-email"
            value={formData.firstName}
            onChange={handleChange}
            required
          />
          <input
            type="number"
            name="lastName"
            placeholder="PHONE NUMBER"
            className="input-name-email"
            value={formData.lastName}
            onChange={handleChange}
            required
          />
          <input
          
            type="email"
            name="email"
            placeholder="EMAIL"
            className=" mailbox"
            value={formData.email}
            onChange={handleChange}
            required
          />
          <button type="submit" className="submit-button">
            SUBMIT
          </button>
        </form>
      </div>

      <footer className="footer-end ">
        <div className="row ">
          <div className="copyright col-6 text-start">
            <p>Copyright © 2024 Armure PPF. All Rights Reserved.</p>
          </div>
          <div className="social-icons col-6 text-end ">
            <a
              href="https://www.facebook.com/profile.php?id=100090208851427"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebook />
            </a>
            <a
              href="https://wa.me/+923002411366"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaWhatsapp />
            </a>
            <a
              href="https://www.instagram.com/armureppf.pk/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram />
            </a>
          </div>
        </div>
      </footer>
      </div>
    </>
  );
};

export default Footer;
