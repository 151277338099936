import React from "react";
import "./Section.css";

const Section = () => {
  return (
    <>
      <div className="section-container">
        <div className="content">
          <h4 className="small-heading">OUR PREMIUM RAW MATERIAL</h4>
          <h1 className="big-heading">PARTNERS</h1>
         
           <div className="container ">
      <div className="row logos">
        <div className="col-md-4  col-6">
          <img src="/partner1.png" className="img-fluid logo1" alt="logo 1" />
        </div>
        <div className="col-md-4  col-6">
          <img src="/partner2.png" className="img-fluid logo2" alt="logo 2" />
        </div>
        <div className="col-md-4  col-6">
          <img src="/partner3.png" className="img-fluid logo3" alt="logo 3" />
        </div>
        <div className="col-md-4  col-6">
          <img src="/partner4.png" className="img-fluid logo4" alt="logo 4" />
        </div>
        <div className="col-md-4  col-6">
          <img src="/partner5.png" className="img-fluid   logo5" alt="logo 5"/>
        </div>
        <div className="col-md-4  col-6">
          <img src="/partner6.png" className="img-fluid logo6 " alt="logo 6" />
        </div>
      </div>
    </div>
        </div>
      </div>
    </>
  );
};

export default Section;
