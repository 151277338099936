import React from 'react'
import './Ultimate.css'
import Ultimateicon from "./Ultimateicon.png";
import Ultimatetable from "./Ultimatetable.png";
const Ultimate = () => {
  return (
    <>
       {/* Section with a background image and two headings */}
       <section className="bg-image-section d-flex align-items-start justify-content-start ">
          <div className="text-container  ">
            <h3 className="top ">7 YEARS</h3>
           <br /><h3 className="bottom">WARRANTY</h3>
          </div>
        </section>

        {/* Section with text and image */}
        <section className="d-flex flex-column flex-lg-row  section-content">
          <div className="left-content col-lg-6 col-12">
            <h3 className="up">ARMURE</h3>
            <br />
            <h2 className="centre ">ULTIMATE</h2>
            <br />
            
            
            <ul className=" points">
              <li>
                
                Protects Car Surface from stonechips, scratches & road salt.
              </li>
              <li>
                Swirl Marks and minor scratches disappear with exposure to heat
                from engine or the sun.
              </li>
              <li>
                Optically clear with a glosssy finish.
              </li>
              <li>Anti Yellowing.</li>
            </ul>
          </div>
          <div className="right-content col-lg-6 col-12">
            <img src={Ultimateicon} alt="Armure Ultra" className="img-fluid" />
          </div>
        </section>

        {/* Full-width image */}
        <section className="90 table">
          <img src={Ultimatetable} alt="Full Width" className="img-fluid w-90" />
        </section>
    </>
  )
}

export default Ultimate
